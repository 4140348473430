/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .portfolio {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        min-width: 20rem;
    }

    .portfolioSectionHeader {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        font-size: 2rem;
    }

    .portfolioSectionTitle {
        color: #ffffff;
        margin-bottom: 2rem;
        margin-left: 1rem;
    }

    .portfolioTitleNumber {
        color: #80ffff;
    }

    .itemsFlex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .portfolioItemBox {
        display: flex;
        flex-direction: column;
        background-color: white;
        max-width: 18rem;
        min-height: 28rem;
        margin-top: 2rem;
        border-radius: 1rem;
    }

    .portfolioItemImage {
        max-width: 18rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    .portfolioItemTitle {
        margin: 1rem;
    }

    .portfolioItemDescription {
        margin: 1rem;
        font-size: 1.2rem;
    }

    .portfolio-roboto-bold {
        font-weight: 900;
        font-size: 1.5rem;
    }

    .links {
        display: flex;
        flex-direction: column;
        font-size: 1.2rem;
    }

    .portfolioHorizontalLine {
        display: none;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .portfolio {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        min-width: 20rem;
    }

    .portfolioSectionHeader {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    .portfolioSectionTitle {
        color: #ffffff;
        margin-bottom: 2rem;
        margin-left: 1rem;
        font-size: 1.5rem;
    }

    .portfolioTitleNumber {
        color: #80ffff;
        font-size: 1.5rem;
    }

    .itemsFlex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .portfolioItemBox {
        display: flex;
        flex-direction: column;
        background-color: white;
        max-width: 18rem;
        min-height: 28rem;
        margin-top: 2rem;
        border-radius: 1rem;
    }

    .portfolioItemImage {
        max-width: 18rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    .portfolioItemTitle {
        margin: 1rem;
    }

    .portfolioItemDescription {
        margin: 1rem;
        font-size: 1.2rem;
    }

    .portfolio-roboto-bold {
        font-weight: 900;
        font-size: 1.5rem;
    }

    .links {
        display: flex;
        flex-direction: column;
        font-size: 1.2rem;
    }

    .portfolioHorizontalLine {
        display: none;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .portfolio {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        min-width: 40rem;
    }

    .portfolioSectionHeader {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .portfolioSectionTitle {
        color: #ffffff;
        margin-bottom: 2rem;
        margin-left: 1rem;
        font-size: 2rem;
    }

    .portfolioTitleNumber {
        color: #80ffff;
        font-size: 2rem;
    }

    .itemsFlex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .portfolioItemBox {
        display: flex;
        flex-direction: column;
        background-color: white;
        max-width: 18rem;
        min-height: 28rem;
        margin-top: 2rem;
        border-radius: 1rem;
    }

    .portfolioItemImage {
        max-width: 18rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    .portfolioItemTitle {
        margin: 1rem;
    }

    .portfolioItemDescription {
        margin: 1rem;
        font-size: 1.2rem;
    }

    .portfolio-roboto-bold {
        font-weight: 900;
        font-size: 1.5rem;
    }

    .links {
        display: flex;
        flex-direction: column;
        font-size: 1.2rem;
    }

    .portfolioHorizontalLine {
        display: flex;
        flex: 1;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .portfolio {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    .portfolioSectionHeader {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .portfolioSectionTitle {
        color: #ffffff;
        margin-bottom: 2rem;
        margin-left: 1rem;
        font-size: 2rem;
    }

    .portfolioTitleNumber {
        color: #80ffff;
        font-size: 2rem;
    }

    .itemsFlex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        max-width: 70rem;
    }

    .portfolioItemBox {
        display: flex;
        flex-direction: column;
        background-color: white;
        max-width: 20rem;
        min-height: 28rem;
        margin-top: 2rem;
        border-radius: 1rem;
    }

    .portfolioItemImage {
        max-width: 20rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    .portfolioItemTitle {
        margin: 1rem;

    }

    .portfolioItemDescription {
        margin: 1rem;
    }

    .portfolio-roboto-bold {
        font-weight: 900;
        font-size: 1.5rem;
    }

    .portfolioHorizontalLine {
        display: flex;
        flex: 1;
    }
}