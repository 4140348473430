/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .navbar {
        font-size: 1.4rem;
    }
    .navbarBG {
        background-color: rgba(90, 109, 110, 1);
    }

    .navbar > .container {
        max-width: 100%;
        margin: 0;
        padding-right: 1rem;
    }

    .navbar-enter {
        opacity: 0;
    }
    .navbar-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
    }
    .navbar-exit {
        opacity: 1;
    }
    .navbar-exit-active {
        opacity: 0;
        transform: translate(0, -100px);
        transition: opacity 300ms, transform 300ms;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .navbar {
        font-size: 1.5rem;
    }
    .navbarBG {
        background-color: rgba(90, 109, 110, 1);
    }

    .navbar > .container {
        max-width: 100%;
        margin: 0;
        padding-right: 1rem;
    }

    .navbar-enter {
        opacity: 0;
    }
    .navbar-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
    }
    .navbar-exit {
        opacity: 1;
    }
    .navbar-exit-active {
        opacity: 0;
        transform: translate(0, -100px);
        transition: opacity 300ms, transform 300ms;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .navbar {
        font-size: 1.5rem;
    }
    .navbarBG {
        background-color: rgba(90, 109, 110, 1);
    }

    .navbar > .container {
        max-width: 100%;
        margin: 0;
        padding-right: 1rem;
    }

    .navbar-enter {
        opacity: 0;
    }
    .navbar-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
    }
    .navbar-exit {
        opacity: 1;
    }
    .navbar-exit-active {
        opacity: 0;
        transform: translate(0, -100px);
        transition: opacity 300ms, transform 300ms;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .navbar {
        font-size: 1rem;
    }
    .navbarBG {
        background-color: rgba(90, 109, 110, 1);
    }

    .navbar > .container {
        max-width: 100%;
        margin: 0;
        padding-right: 1rem;
    }

    .navbar-enter {
        opacity: 0;
    }
    .navbar-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
    }
    .navbar-exit {
        opacity: 1;
    }
    .navbar-exit-active {
        opacity: 0;
        transform: translate(0, -100px);
        transition: opacity 300ms, transform 300ms;
    }
}