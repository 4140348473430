/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .experience {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    .experienceSectionHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        font-size: 1.5rem;
    }

    .experienceSectionTitle {
        color: #ffffff;
        margin-left: 1rem;
    }

    .experienceTitleNumber {
        color: #80ffff;
    }

    .experienceHorizontalLine {
        display: none;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .experience {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    .experienceSectionHeader {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        font-size: 2rem;
    }

    .experienceSectionTitle {
        color: #ffffff;
        margin-bottom: 2rem;
        margin-left: 1rem;
    }

    .experienceTitleNumber {
        color: #80ffff;
    }

    .experienceHorizontalLine {
        display: none;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .experience {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    .experienceSectionHeader {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        font-size: 2rem;
    }

    .experienceSectionTitle {
        color: #ffffff;
        margin-bottom: 2rem;
        margin-left: 1rem;
    }

    .experienceTitleNumber {
        color: #80ffff;
    }

    .experienceHorizontalLine {
        display: flex;
        flex: 1;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .experience {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    .experienceSectionHeader {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        font-size: 2rem;
    }

    .experienceSectionTitle {
        color: #ffffff;
        margin-bottom: 2rem;
        margin-left: 1rem;
    }

    .experienceTitleNumber {
        color: #80ffff;
    }

    .experienceHorizontalLine {
        display: flex;
        flex: 1;
    }
}