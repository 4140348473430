/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: flex-start;
        min-width: 20rem;
    }

    .companyButtons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 5rem;
        margin-bottom: 2rem;
    }

    .jobDescription {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
        max-width: 60rem;
    }

    .title {
        color: white;
    }

    .dates {
        color: white;
    }

    .text {
        color: white;
    }

    .button {
        width: 100%;
        margin-left: .25rem;
        margin-right: .25rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .content {
        display: flex;
        height: 800px;
        flex-direction: column;
        justify-content: flex-start;
        align-content: flex-start;
    }

    .companyButtons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 5rem;
        margin-bottom: 2rem;
    }

    .jobDescription {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
        max-width: 60rem;
    }

    .title {
        color: white;
    }

    .dates {
        color: white;
    }

    .text {
        color: white;
    }

    .button {
        width: 100%;
        margin-left: .25rem;
        margin-right: .25rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .content {
        display: flex;
        height: 700px;
        flex-direction: column;
        justify-content: flex-start;
        align-content: flex-start;
    }

    .companyButtons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 5rem;
        margin-bottom: 2rem;
    }

    .jobDescription {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
        max-width: 60rem;
    }

    .title {
        color: white;
    }

    .dates {
        color: white;
    }

    .text {
        color: white;
    }

    .button {
        width: 100%;
        margin-left: .25rem;
        margin-right: .25rem;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;
        min-height: 25rem;
    }

    .companyButtons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        min-width: 10rem;
        height: 20rem;
        margin-right: 2rem;
    }

    .jobDescription {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
        max-width: 60rem;
    }

    .title {
        color: white;
    }

    .dates {
        color: white;
    }

    .text {
        color: white;
    }

    .middleButton {
        margin-top: .2rem;
        margin-bottom: .2rem;
    }
}
