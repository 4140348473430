/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .App {
        width: 100vw;
    }

    .splashWrapper {
        position: relative;
        top: 0;
        height: 1000px;
        width: 100vw;
        justify-content: center;
    }

    .aboutWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        height: 1500px;
    }

    .experienceWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        height: 1400px;
        width: 100vw;
    }

    .portfolioWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        width: 100vw;
    }

    .contactWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        height: 100vh;
        width: 100vw;
    }

    .splashFlex {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .aboutFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .experienceFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .portfolioFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .contactFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .socialIconsWrapper {
        position: fixed;
        bottom: 2rem;
        height: 15vh;
    }

    .socialIconsFlex {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        width: 2vw;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .App {
        width: 100vw;
    }

    .splashWrapper {
        position: relative;
        top: 0;
        height: 1000px;
        width: 100vw;
        justify-content: center;
    }

    .aboutWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        height: 1300px;
        width: 100vw;
    }

    .experienceWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        height: 1300px;
        width: 100vw;
    }

    .portfolioWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        width: 100vw;
    }

    .contactWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        width: 100vw;
    }

    .splashFlex {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .aboutFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .experienceFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .portfolioFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .contactFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .socialIconsWrapper {
        position: fixed;
        bottom: 2rem;
        height: 15vh;
    }

    .socialIconsFlex {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        width: 2vw;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .App {
        height: 300%;
        width: 100%;
    }

    .splashWrapper {
        position: relative;
        top: 0;
        height: 1000px;
        width: 100vw;
        justify-content: center;
    }

    .aboutWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        height: 1000px;
        width: 100vw;
    }

    .experienceWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        height: 1200px;
        width: 100vw;
    }

    .portfolioWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        width: 100vw;
    }

    .contactWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        width: 100vw;
    }

    .splashFlex {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .aboutFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .experienceFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .portfolioFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .contactFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .socialIconsWrapper {
        position: fixed;
        bottom: 2rem;
        height: 15vh;
    }

    .socialIconsFlex {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        width: 2vw;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .App {
        height: 300%;
        width: 100%;
    }

    .splashWrapper {
        position: relative;
        top: 0;
        height: 100vh;
        width: 100vw;
        justify-content: center;
    }

    .aboutWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        height: 100vh;
        width: 100vw;
    }

    .experienceWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        height: 100vh;
        width: 100vw;
    }

    .portfolioWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        width: 100vw;
    }

    .contactWrapper {
        position: relative;
        justify-content: center;
        align-content: center;
        height: 100vh;
        width: 100vw;
    }

    .splashFlex {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .aboutFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .experienceFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .portfolioFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .contactFlex {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .socialIconsWrapper {
        position: fixed;
        bottom: 2rem;
        height: 15vh;
    }

    .socialIconsFlex {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        width: 2vw;
    }
}