/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .splashTitle {
        margin: auto;
    }

    .line1 {
        color: #80ffff;
        font-family: Roboto Mono, monospace;
        font-size: 1.2rem;
        font-weight: 900;
        padding: 0;
        line-height: 2rem;
    }

    .line2 {
        color: #ffffff;
        font-family: Montserrat, sans-serif;
        font-size: 3.5rem;
        padding: 0;
        line-height: 3.5rem;
    }

    .line2FullStop {
        color: #ffffff;
        font-family: Overpass Mono, monospace;
        font-size: 3.5rem;
        margin-left: -.7rem; /*Not sure if this will look okay on random devices*/
        line-height: 1.3rem;
    }

    .line3 {
        color: #5a6d6e;
        font-family: Montserrat, sans-serif;
        font-size: 2.5rem;
        padding: 0;
        line-height: 2.5rem;
        margin-bottom: 1rem;
    }

    .line3FullStop {
        color: #5a6d6e;
        font-family: Overpass Mono, monospace;
        font-size: 3rem;
        margin-left: -.6rem; /*Not sure if this will look okay on random devices*/
        line-height: 1.3rem;
        margin-bottom: 1rem;
    }

    .line4 {
        color: #ffffff;
        font-family: Overpass Mono, monospace;
        font-size: .8rem;
        padding: 0;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .splashTitle {
        margin: auto;
    }

    .line1 {
        color: #80ffff;
        font-family: Roboto Mono, monospace;
        font-size: 1.7rem;
        font-weight: 900;
        padding: 0;
        line-height: 4rem;
    }

    .line2 {
        color: #ffffff;
        font-family: Montserrat, sans-serif;
        font-size: 5rem;
        padding: 0;
        line-height: 4rem;
    }

    .line2FullStop {
        color: #ffffff;
        font-family: Overpass Mono, monospace;
        font-size: 4rem;
        margin-left: -.7rem; /*Not sure if this will look okay on random devices*/
        line-height: 1.3rem;
    }

    .line3 {
        color: #5a6d6e;
        font-family: Montserrat, sans-serif;
        font-size: 4rem;
        padding: 0;
        line-height: 4rem;
        margin-bottom: 1rem;
    }

    .line3FullStop {
        color: #5a6d6e;
        font-family: Overpass Mono, monospace;
        font-size: 4rem;
        margin-left: -.9rem; /*Not sure if this will look okay on random devices*/
        line-height: 1.3rem;
        margin-bottom: 1rem;
    }

    .line4 {
        color: #ffffff;
        font-family: Overpass Mono, monospace;
        font-size: 1rem;
        padding: 0;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .splashTitle {
        margin: auto;
    }

    .line1 {
        color: #80ffff;
        font-family: Roboto Mono, monospace;
        font-size: 1.7rem;
        font-weight: 900;
        padding: 0;
        line-height: 4rem;
    }

    .line2 {
        color: #ffffff;
        font-family: Montserrat, sans-serif;
        font-size: 5rem;
        padding: 0;
        line-height: 4rem;
    }

    .line2FullStop {
        color: #ffffff;
        font-family: Overpass Mono, monospace;
        font-size: 4rem;
        margin-left: -.7rem; /*Not sure if this will look okay on random devices*/
        line-height: 1.3rem;
    }

    .line3 {
        color: #5a6d6e;
        font-family: Montserrat, sans-serif;
        font-size: 5rem;
        padding: 0;
        line-height: 4.5rem;
        margin-bottom: 1rem;
    }

    .line3FullStop {
        color: #5a6d6e;
        font-family: Overpass Mono, monospace;
        font-size: 4rem;
        margin-left: -.9rem; /*Not sure if this will look okay on random devices*/
        line-height: 1.3rem;
        margin-bottom: 1rem;
    }

    .line4 {
        color: #ffffff;
        font-family: Overpass Mono, monospace;
        font-size: 1rem;
        padding: 0;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .splashTitle {
        margin: auto;
    }

    .line1 {
        color: #80ffff;
        font-family: Roboto Mono, monospace;
        font-size: 1.7rem;
        font-weight: 900;
        padding: 0;
        line-height: 4rem;
    }

    .line2 {
        color: #ffffff;
        font-family: Montserrat, sans-serif;
        font-size: 5rem;
        padding: 0;
        line-height: 4rem;
    }

    .line2FullStop {
        color: #ffffff;
        font-family: Overpass Mono, monospace;
        font-size: 4rem;
        margin-left: -.7rem; /*Not sure if this will look okay on random devices*/
        line-height: 1.3rem;
    }

    .line3 {
        color: #5a6d6e;
        font-family: Montserrat, sans-serif;
        font-size: 5rem;
        padding: 0;
        line-height: 4.5rem;
        margin-bottom: 1rem;
    }

    .line3FullStop {
        color: #5a6d6e;
        font-family: Overpass Mono, monospace;
        font-size: 4rem;
        margin-left: -.9rem; /*Not sure if this will look okay on random devices*/
        line-height: 1.3rem;
        margin-bottom: 1rem;
    }

    .line4 {
        color: #ffffff;
        font-family: Overpass Mono, monospace;
        font-size: 1rem;
        padding: 0;
    }
}
